export const countries = {
  '/en-ca': {
    label: 'Канада',
    language: 'EN',
    country: 'CA',
    currency: 'CAD',
    link: 'https://antheiawoman.com/en-ca'
  },
  '/en-de': {
    label: 'Европейский союз',
    language: 'EN',
    country: 'DE',
    currency: 'EUR',
    link: 'https://antheiawoman.com/en-de'
  },
  '/en-am': {
    label: 'Средний восток',
    language: 'EN',
    country: 'AM',
    currency: 'USD',
    link: 'https://antheiawoman.com/en-am'
  },
  default: {
    label: 'Россия',
    language: 'EN',
    country: 'RU',
    currency: 'EUR',
    link: ''
  },
  '/en-gb': {
    label: 'Великобритания',
    language: 'EN',
    country: 'GB',
    currency: 'GBP',
    link: 'https://antheiawoman.com/en-gb'
  },
  'en-us': {
    label: 'Соединенные Штаты',
    language: 'EN',
    country: 'US',
    currency: 'USD',
    link: 'https://antheiawoman.com'
  },
};